export const gateway = "https://sbx-belowcost-shopper-api.link-nonprod.wawanesalife.com";
export const assets = "https://sbx-assets.link-nonprod.wawanesalife.com/brokers/belowcost";
export const googleApiKey = "AIzaSyD8GAXLH7FxUmF13tfHgXVAU6fWk66UH3k";
export const brokerConfig = {
	enabled: "yes",
	authorized_nb: "yes",
	authorized_mb: "yes",
	authorized_pe: "yes",
	authorized_yt: "yes",
	authorized_sk: "yes",
	authorized_qc: "no",
	authorized_nt: "yes",
	authorized_nu: "yes",
	authorized_ns: "yes",
	authorized_nl: "yes",
	authorized_on: "yes",
	authorized_ab: "yes",
	authorized_bc: "yes",
	"split": '0.6'
};
export const brokerBranding = {
	"sbx-belowcost-shopper.link-nonprod.wawanesalife.com": {
		a: {
			environment: "sbx",
			broker: "belowcost",
			website: "https://live.belowcost.net/",
			accessibilityLink: "https://en.wikipedia.org/wiki/Web_Content_Accessibility_Guidelines",
			themeSwitcher: "show",
			ftcLink: "https://www.fsrao.ca/regulation/guidance/fair-treatment-customers-insurance",
			privacyLink: "https://www.priv.gc.ca/en/privacy-topics/privacy-laws-in-canada/the-personal-information-protection-and-electronic-documents-act-pipeda/",
			layout: "modern",
			scope: "belowcost#branding#sbx-belowcost-shopper.link-nonprod.wawanesalife.com#a",
			approve_company: "Link Platform",
			email: "bob@belowcost.lol",
			video_link: "SF1CFpZI1tY",
			g4: "G-2QM64W4BM4",
			photos: [
				"carousel1.jpg",
				"carousel2.jpg",
				"carousel3.jpg",
				"carousel4.jpg",
				"carousel5.jpg",
				"carousel6.jpg",
				"carousel7.jpg",
				"carousel8.jpg",
				"carousel9.jpg",
				"carousel10.jpg",
				"carousel11.jpg",
			],
			approve_user: "link_belowcost",
			logoPosition: "menu",
			environment_id: "sbx",
			brands: [
				"sbx-belowcost-shopper.link-nonprod.wawanesalife.com",
			],
			"maps": {
				"True North Tower": [
					"236 Carlton",
					"Winnipeg",
					"MB",
					"R3C 1P5",
				],
				"River Park branch": [
					"845 Dakota St Unit 30",
					"Winnipeg",
					"MB",
					"R2M 5M3",
				],
				"Jack Blick branch": [
					"1440 Jack Blick Ave",
					"Winnipeg",
					"MB",
					"R3G 0L4",
				],
				"Saskatoon branch": [
					"100-3020 Preston Ave S",
					"Saskatoon",
					"SK",
					"S7T 0V2",
				],
				"Waverley branch": [
					"840 Waverley St",
					"Winnipeg",
					"MB",
					"R3T 5Z7",
				],
			},
			introduction: "null",
			phone: "(204) 555-4321",
			menuPhone: "button",
			ab: "a",
			logo: "a.png",
			brand_name: "sbx-belowcost-shopper.link-nonprod.wawanesalife.com",
		},
		b: {
			environment: "sbx",
			broker: "belowcost",
			website: "https://live.belowcost.net/",
			accessibilityLink: "https://en.wikipedia.org/wiki/Web_Content_Accessibility_Guidelines",
			themeSwitcher: "show",
			ftcLink: "https://www.fsrao.ca/regulation/guidance/fair-treatment-customers-insurance",
			privacyLink: "https://www.priv.gc.ca/en/privacy-topics/privacy-laws-in-canada/the-personal-information-protection-and-electronic-documents-act-pipeda/",
			layout: "modern",
			scope: "belowcost#branding#sbx-belowcost-shopper.link-nonprod.wawanesalife.com#b",
			approve_company: "Link Platform",
			email: "bob@belowcost.lol",
			video_link: "SF1CFpZI1tY",
			g4: "G-2QM64W4BM4",
			photos: [
				"carousel1.jpg",
				"carousel2.jpg",
				"carousel3.jpg",
				"carousel4.jpg",
				"carousel5.jpg",
				"carousel6.jpg",
				"carousel7.jpg",
				"carousel8.jpg",
				"carousel9.jpg",
				"carousel10.jpg",
				"carousel11.jpg",
			],
			approve_user: "link_belowcost",
			logoPosition: "menu",
			environment_id: "sbx",
			brands: [
				"sbx-belowcost-shopper.link-nonprod.wawanesalife.com",
			],
			"maps": {
				"True North Tower": [
					"236 Carlton",
					"Winnipeg",
					"MB",
					"R3C 1P5",
				],
				"River Park branch": [
					"845 Dakota St Unit 30",
					"Winnipeg",
					"MB",
					"R2M 5M3",
				],
				"Jack Blick branch": [
					"1440 Jack Blick Ave",
					"Winnipeg",
					"MB",
					"R3G 0L4",
				],
				"Saskatoon branch": [
					"100-3020 Preston Ave S",
					"Saskatoon",
					"SK",
					"S7T 0V2",
				],
				"Waverley branch": [
					"840 Waverley St",
					"Winnipeg",
					"MB",
					"R3T 5Z7",
				],
			},
			introduction: "null",
			phone: "(204) 555-4321",
			menuPhone: "button",
			ab: "b",
			logo: "b.png",
			brand_name: "sbx-belowcost-shopper.link-nonprod.wawanesalife.com",
		},
	},
};

//updated on Thu Aug 15 2024 05:42:23 GMT+0000 (Coordinated Universal Time)
